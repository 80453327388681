.appBar {
    background-color: #fff !important;
    box-shadow: none !important;
    padding: 0 30px;
}

.projectDashboardMenu {
  font-family: 'museo-sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  top: 20% !important;
  left: 15px !important;
  width: 100%;
}

.burgerIconBox {
  display: flex;
  justify-content: flex-end;
}

.headerLogo {
    height: 40px;
    width: 40px;
}

.claNavLinkMenu {
  font-family: 'museo-sans', sans-serif;
  font-style: normal; 
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;

  color: #565656;
}

.claNavLinkMenu:hover {
  color: #565656;
}

.claNavLink {
  position: relative;
  font-family: 'museo-sans', sans-serif;
  font-style: normal; 
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;

  color: #565656;

  padding: 45px 0rem 1rem 0rem;
  margin-left: 5px;
  margin-right: 25px; 
}

.claNavLinkDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.claNavLink:hover {
  color: #565656;
}

.claNavLinkActive {
  font-weight: 600;
  border-bottom: 5px solid #76D2D3 !important;
}

.backLinkBox {
  position: absolute;
  left: 60px;
  bottom: 37px;
  z-index: 1;
}

.backLink {
  position: relative;
  font-family: 'museo-sans', sans-serif;
  font-style: normal; 
  font-size: 10px;
  line-height: 16px;
  text-decoration: none;

  color: #565656;
}

.backLink:hover {
  color: #565656;
}

   