.bannerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8c5c5;
    width: 100%;
    padding: 10px 0;
    gap: 10px;
}
.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.buttons{
    margin:0;
    padding: 3px 5px !important;
    height: 35px
}